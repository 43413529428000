import React from 'react'
import Title from '../layouts/Title'
import { lamaDemir, silmeDemir,
  celikHasir, kareProfil, dikdörtgenProfil, npiProfil, npuProfil, ipeDemir,
  heaProfil, hebProfil, ruloSac, levhaSac, dkpSac, galvanizliSac, olukluLevha, trapezLevha
} from "../../assets/index";
import ÜrünlerCard from './ÜrünlerCard';

const Ürünler = () => {
  return (
    <section id="Ürünler" className="w-full py-12 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title title="Ürünlerimize göz atın" des="Ürünlerimiz" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-6 xl:gap-6">
        <ÜrünlerCard
          title="Lama Demirler"
          src={lamaDemir}
        />
        <ÜrünlerCard
          title="Silme Demirler"
          src={silmeDemir}
        />
        <ÜrünlerCard
          title="Çelik Hasır"
          src={celikHasir}
        />
        <ÜrünlerCard
          title="Kare Profil"
          src={kareProfil}
        />
        <ÜrünlerCard
          title="Dikdörtgen Profil"
          src={dikdörtgenProfil}
        />
        <ÜrünlerCard
          title="NPİ Profiller"
          src={npiProfil}
        />
        <ÜrünlerCard
          title="NPU Profiller"
          src={npuProfil}
        />
        <ÜrünlerCard
          title="İPE Profiller"
          src={ipeDemir}
        />
        <ÜrünlerCard
          title="HEA Profiller"
          src={heaProfil}
        />
        <ÜrünlerCard
          title="HEB Profiller"
          src={hebProfil}
        />
        <ÜrünlerCard
          title="Rulo Sac"
          src={ruloSac}
        />
        <ÜrünlerCard
          title="Levha Sac"
          src={levhaSac}
        />
        <ÜrünlerCard
          title="DKP Sac"
          src={dkpSac}
        />
        <ÜrünlerCard
          title="Galvanizli Düz Sac"
          src={galvanizliSac}
        />
        <ÜrünlerCard
          title="Oluklu Levha"
          src={olukluLevha}
        />
        <ÜrünlerCard
          title="Trapez Levha"
          src={trapezLevha}
        />
      </div>
    </section>
  );
}

export default Ürünler