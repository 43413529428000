export const navLinksdata = [
  {
    _id: 1001,
    title: "Ana Sayfa",
    link: "AnaSayfa",
  },
  {
    _id: 1002,
    title: "Özellikler",
    link: "Özellikler",
  },
  {
    _id: 1003,
    title: "Ürünler",
    link: "Ürünler",
  },
  {
    _id: 1004,
    title: "Deneyim",
    link: "Deneyim",
  },
  {
    _id: 1005,
    title: "Referans",
    link: "Referans",
  },
  {
    _id: 1006,
    title: "Bağlantı",
    link: "Bağlantı",
  },
];