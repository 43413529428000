import React from 'react';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import Media from './Media';

const SolGiris = () => {
  const [text] = useTypewriter({
    words: ['DEMİR', 'ÇELİK', 'İNŞAAT'],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });

  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-10 mt-0">
      <div className="flex flex-col gap-10">
        <h4 className="text-2xl font-normal">HOŞGELDİNİZ</h4>
        <h1 className="text-5xl font-bold text-white">
          ARICAN GROUP <span className="text-red-600 capitalize">{text}</span>
        </h1>
        {/* <h2 className="text-3xl font-bold text-white">
          ARICAN <span>{text}</span>
          <Cursor cursorBlinking="false" cursorStyle="|" cursorColor="#ff014f" />
        </h2> */}
        
      </div>
      {/* Media */}
      <Media />
    </div>
  );
};

export default SolGiris;
