import React, { useState, useEffect } from 'react';
import { bannerImg } from '../../assets/index';

const SagGiris = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = bannerImg;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  const bannerStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: imageLoaded ? 1 : 0,
    transform: `translateX(${imageLoaded ? '0' : '100%'})`,
    transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
  };

  return (
    <div className="w-full lgl:w-1/2 relative overflow-hidden">
      <img
        style={bannerStyle}
        src={bannerImg}
        alt="bannerImg"
        className={imageLoaded ? 'push-animation' : ''}
      />
    </div>
  );
};

export default SagGiris;
