import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const VizyonMisyon = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex flex-col md:flex-row gap-20"
    >
      <div className="md:w-1/2">
        <div className="flex flex-col gap-4">
          <h2 className="text-4xl font-bold">Vizyonumuz</h2>
        </div>
        <div className="mt-14 w-full md:w-[600px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-5">
          <ResumeCard
            title="Müşteri Odaklılık:"
            des="Arıcan Group olarak, vizyonumuzun temel taşı müşteri odaklılık ilkesidir. 
            Müşterilerimizin beklentilerini anlamak ve onlara değerli, özgün çözümler sunmak, iş süreçlerimizin merkezindedir. 
            Her projede müşteri memnuniyetini en üst düzeye çıkarmak ve uzun vadeli güvenilir ilişkiler kurmak için çaba sarf ediyoruz."
          />
          <ResumeCard
            title="Yenilikçilik ve Teknoloji"
            des="Sektördeki hızlı değişimlere ayak uydurmak ve öncü olmak adına yenilikçilik ve teknolojiyi sürekli olarak benimsemeyi hedefliyoruz. 
            Projelerimizde modern teknolojileri kullanarak, daha etkili, çevre dostu ve sürdürülebilir çözümler üretmeyi amaçlıyoruz. 
            Ar-Ge'ye verdiğimiz önemle, sektördeki en son gelişmeleri takip ediyor ve uyguluyoruz."
          />
          <ResumeCard
            title="Toplumsal Sorumluluk"
            des="Arıcan Group olarak, toplumsal sorumluluk anlayışımızı sadece işimizi değil, aynı zamanda çevremizi ve toplumu da kapsayacak şekilde şekillendiriyoruz. 
            Sürdürülebilirlik ilkelerini benimseyerek, çevre dostu uygulamaları destekliyor ve toplumumuza değer katmayı amaçlıyoruz. 
            Eğitim, çevre koruma ve sosyal projelere katkıda bulunarak, daha yaşanabilir bir dünya için çalışıyoruz."
          />
        </div>
      </div>
      <div className="md:w-1/2">
        <div className="flex flex-col gap-4">
          <h2 className="text-4xl font-bold">Misyonumuz</h2>
        </div>
        <div className="mt-14 w-full md:w-[600px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-5">
          <ResumeCard
            title="Kalite ve Güven"
            des="Arıcan Group olarak misyonumuz, inşaat ve demir-çelik sektörlerinde kalite standartlarını en üst seviyede tutarak müşterilerimize güvenilir çözümler sunmaktır. 
            Her bir projemizde, malzeme seçiminden uygulama süreçlerine kadar titizlikle çalışarak, müşterilerimizin beklentilerini aşmayı hedefliyoruz."
          />
          <ResumeCard
            title="İnovasyon ve Verimlilik"
            des="Misyonumuz, sürekli olarak yenilikçilik ve verimliliği benimsemek ve bu prensipleri iş süreçlerimize entegre etmek üzerine odaklanmıştır. 
            İnşaat ve demir-çelik alanındaki gelişmeleri sürekli olarak takip ediyoruz. Yeniliğe açık bir şekilde modern teknolojileri kullanarak projelerimizi 
            daha etkili ve sürdürülebilir kılmak için çaba sarf ediyoruz."
          />
          <ResumeCard
            title="Çevre ve Toplum Bilinci"
            des="Arıcan Group olarak, çevre dostu uygulamalar ve toplumsal sorumluluk anlayışıyla hareket ediyoruz. 
            Projelerimizi çevresel etkileri en aza indirerek, enerji verimliliğini artırarak ve toplumumuza katkıda bulunarak gerçekleştiriyoruz. 
            Çevreye duyarlı ve toplum odaklı bir yaklaşımı misyonumuzun temelinde bulundurarak, sürdürülebilir bir gelecek için çalışıyoruz."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default VizyonMisyon;
