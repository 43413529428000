import React from 'react'

const AltYazi = () => {
  return (
    <div className="w-full py-10">
      <p className="text-center text-gray-500 text-base">
        © 2023. Tüm hakları DoSoft Bilişim ve Yazılıma aittir.
      </p>
    </div>
  );
}

export default AltYazi