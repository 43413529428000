// Header.js
import React from 'react';
import Slider from 'react-slick';
import backgroundImage from '../../assets/images/backgroundImage.png';
import backgroundImage2 from '../../assets/images/backgroundImage2.png';
import backgroundImage3 from '../../assets/images/backgroundImage3.png';
import backgroundImage4 from '../../assets/images/backgroundImage4.png';
import backgroundImage5 from '../../assets/images/backgroundImage5.png';
import backgroundImage6 from '../../assets/images/backgroundImage6.png';
import backgroundImage7 from '../../assets/images/backgroundImage7.png';

const Header = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: 0, // Bu değeri sıfır olarak ayarlayın
  };

  return (
    <section id="Header" className="w-full py-2 border-b-[1px] border-b-black bg-gradient-to-r from-[#1e2024] to-[#23272b] overflow-x-hidden">
      <div className="max-w-8xl mx-auto">
        <Slider {...settings}>
          {[backgroundImage, backgroundImage2, backgroundImage3, backgroundImage4, backgroundImage5, backgroundImage6, backgroundImage7].map((images, index) => (
            <div key={index} className="w-full h-[200px] md:w-[200px] lg:w-[200px]">
              <div className="w-full h-full rounded-lg shadow-shadowOne flex flex-col justify-center items-center overflow-hidden">
                <img
                  className="w-full h-full rounded-lg object-cover images-img"
                  src={images}
                  alt={`images${index + 1}`}
                  style={{ filter: 'brightness(80%)', width: '100%' }}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Header;
