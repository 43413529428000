import React from 'react'
import { motion } from 'framer-motion';
import ResumeCard from './ResumeCard';

const İsler = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex flex-col md:flex-row gap-20"
    >
      <div className="md:w-1/2">
        <div className="flex flex-col gap-4">
          <h2 className="text-4xl font-bold">İş Kalitesi</h2>
        </div>
        <div className="mt-14 w-full md:w-[600px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-5">
          <ResumeCard
            title="İş Kalitesi"
            des="Arıcan Group, her projede yüksek standartlarda iş kalitesi sunmaya odaklanır. 
            Bu kalite anlayışı, malzeme seçiminden uygulama süreçlerine kadar titiz bir denetim ve yönetimle desteklenir. 
            Şirket, müşteri beklentilerini aşmayı ve projelerinde estetik, dayanıklılık ve güvenilirlik unsurlarını bir araya getirmeyi amaçlar."
          />
          <ResumeCard
            title="Teknoloji ve İnovasyon"
            des="Arıcan Group, iş kalitesini artırmak ve müşterilere öncü çözümler sunmak adına teknoloji ve inovasyona büyük bir önem verir. 
            Sektöre uygun en son teknolojileri takip eder ve bu teknolojileri projelerine entegre eder. 
            Bu sayede, daha etkili, çevre dostu ve sürdürülebilir projeler üreterek iş kalitesini en üst seviyeye çıkarır."
          />
          <ResumeCard
            title="Memnuniyet ve Geri Bildirim"
            des="Arıcan Group, iş kalitesini değerlendirmek ve sürekli olarak iyileştirmek amacıyla müşteri memnuniyetine büyük önem verir. 
            Her aşamada müşterileri ile etkileşimde bulunarak ihtiyaçlarını anlamaya çalışır ve geri bildirimleri dikkate alır. Bu müşteri odaklı yaklaşım, 
            Arıcan Group'un projelerinde yüksek iş kalitesini sürdürmesine ve müşteri memnuniyetini sağlamasına olanak tanır."
          />
        </div>
      </div>
      {/* part Two */}

      <div className="md:w-1/2">
        <div className="flex flex-col gap-4">
          <h2 className="text-4xl font-bold">İş Deneyimi</h2>
        </div>
        <div className="mt-14 w-full md:w-[600px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-5">
          <ResumeCard
            title="Geniş Kapsamlı Deneyim"
            des="Arıcan Group, inşaat ve demir-çelik sektöründe geniş bir projeler portföyüne sahiptir. 
            Şirket, konut, ticari, endüstriyel ve altyapı projeleri gibi farklı kategorilerde faaliyet göstermiştir. 
            Bu çeşitlilik, Arıcan Group'un farklı ölçekteki ve karmaşıklıktaki projelerde başarıyla yer almasını sağlamıştır."
          />
          <ResumeCard
            title="Uzman ve Güçlendirilmiş Kadro"
            des="Arıcan Group, sektörde uzun yılların getirdiği deneyimi, uzman bir kadro ile birleştiriyoruz.
             Şirketimizin profesyonel ekibi, modern teknolojik altyapıyla desteklenmiştir ve gelişimimizi son teknolojilere göre devam ettiriyoruz. 
            Bu sayede Arıcan Group, projelerinde en son teknolojileri kullanarak verimliliği artırır ve kaliteyi yükseltir."
          />
          <ResumeCard
            title="Müşteri Odaklı İş Anlayışı"
            des="Arıcan Group'un iş deneyimi, müşteri memnuniyetine odaklı bir iş anlayışıyla şekillenir. 
            Şirketimiz, müşterilerle sürekli iletişim halinde olup ihtiyaçlarına yönelik özel çözümler üretir.
            Proje süreçlerinde şeffaf iletişim ve müşteri geri bildirimleriyle iş deneyimini sürekli olarak geliştirir.
            Bu yaklaşım, Arıcan Group'un sektörde güvenilir bir iş deneyimi sunmasına katkı sağlar."
          />
        </div>
      </div>
    </motion.div>
  );
}

export default İsler