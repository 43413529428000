import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Basarı = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex flex-col md:flex-row gap-20"
    >
      <div className="md:w-1/2">
        <div className="flex flex-col gap-4">
          <h2 className="text-4xl font-bold">Şirket Başarıları</h2>
        </div>
        <div className="mt-14 w-full md:w-[600px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-5">
          <ResumeCard
            title="Uluslararası Projelerde Liderlik:"
            des="Arıcan Group, uluslararası alanda projelerde liderlik yaparak sektördeki üstünlüğünü kanıtlamıştır. 
            İnşaat, altyapı ve çelik yapı projelerinde gösterdiği uzmanlık, şirketi sektördeki önemli bir aktör haline getirmiştir. 
            Uluslararası standartlara uygun olarak gerçekleştirilen projeler, Arıcan Group'un küresel arenada tanınmasını sağlamıştır."
          />
          <ResumeCard
            title="Yenilikçilik ve Teknolojik Liderlik"
            des="Arıcan Group, sektördeki gelişmeleri takip ederek sürekli olarak yenilikçilik ve en son teknolojileri benimseme konusundaki liderliğini sürdürmektedir. 
            Modern inşaat yöntemleri, çevre dostu teknolojiler ve sürdürülebilir uygulamaları projelerine entegre ederek, şirket hem çevresel hem de ekonomik açıdan sürdürülebilir çözümler üretmektedir." 
          />
          <ResumeCard
            title="Sosyal Sorumluluk"
            des="Arıcan Group, iş kalitesine ve müşteri memnuniyetine odaklI bir şirket olmanın yanı sıra sosyal sorumluluk konusundaki taahhütleriyle de dikkat çekmektedir.
            Mükemmeliği hedefleyen bir yaklaşım benimseyen şirket, aynı zamanda topluma ve çevreye duyarlı bir iş modelini benimsemiştir. 
            Arıcan Group, sadece iş dünyasında değil, toplumsal düzeyde de olumlu bir etki bırakmayı amaçlamaktadır."
          />
        </div>
      </div>
      <div className="md:w-1/2">
        <div className="flex flex-col gap-4">
          <h2 className="text-4xl font-bold">İş Başarıları</h2>
        </div>
        <div className="mt-14 w-full md:w-[600px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-6">
          <ResumeCard
            title="Profesyonel Gelişim ve Liderlik"
            des="Profesyonel kariyerimizde sağladığım başarılar arasında, liderlik yeteneklerimiz ve sürekli profesyonel gelişimimiz öne çıkmaktadır. 
            Kritik projelerde liderlik yaparak, takımı yönlendirme ve projeleri başarıyla tamamlama becerilerimizi geliştiriyoruz. 
            Sektördeki son trendleri ve yönetim tekniklerini takip ederek profesyonel gelişimimize devam ediyoruz."
          />
          <ResumeCard
            title="İnovasyon ve Problem Çözme"
            des="Kişisel başarılarımız arasında yer alan önemli bir unsuru, inovasyon ve problem çözme becerilerim oluşturuyor. 
            Geliştirdiğimiz çözümler, iş süreçlerindeki verimliliği artırmak ve sürdürülebilir çözümler üretmek adına kritik bir rol oynuyor. 
            Zorluklarla karşılaştığımda yaratıcı çözümler üretebilme yeteneğimiz, başarılarımıza önemli bir katkı sağlıyor."
          />
          <ResumeCard
            title="İletişim ve İşbirliği"
            des="İletişim ve işbirliği becerilerimiz, farklı paydaşlarla etkili iletişim kurma ve takımlar arasında uyum sağlama yeteneğimizi yansıtmaktadır. 
            Farklı disiplinlerden gelen ekiplerle başarıyla çalışarak, projeleri zamanında ve bütçe dahilinde tamamlama başarıları elde ettik. 
            İşbirliği odaklı yaklaşımımız ve etkili iletişim becerilerim, projelerdeki başarımızın temelini oluşturuyor."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Basarı;
