import React from 'react';
import SolGiris from './SolGiris';
import SagGiris from './SagGiris';

// Import the background image

const Giris = () => {
  const bannerStyle = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  return (
    <section
      id="AnaSayfa"
      className="w-full flex flex-col gap-0 xl:gap-0 lgl:flex-row items-center border-b-[1px] font-titleFont border-b-black pb-0"
      style={bannerStyle}
    >
      <SolGiris />
      <SagGiris />
    </section>
  );
};

export default Giris;
