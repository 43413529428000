import React from 'react'
import { contactImg } from "../../assets/index";

const BaglantiSol = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-6 justify-center">
      <img
        className="w-full h-62 object-cover rounded-lg mb-0"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-6">
        <h3 className="text-2xl font-bold text-white">Arıcan Group Demir-Çelik-İnşaat</h3>
        
      </div>
      
    </div>
  );
}

export default BaglantiSol