import React from "react";
import Giris from "./components/giris/Giris";
import Header from "./components/giris/Header";
import Baglanti from "./components/baglanti/Baglanti";
import Ozellikler from "./components/ozellikler/Ozellikler";
import AltYazi from "./components/footer/AltYazi";
import Navbar from "./components/navbar/Navbar";
import Ürünler from "./components/urunler/Ürünler";
import Resume from "./components/deneyim/Resume";
import Referans from "./components/referans/Referans";

function App() {
  return (
    <div className="w-full h-auto bg-bodyColor text-lightText px-4">
        <Navbar />
      <div className="max-w-screen-xl mx-auto">
        <Header />
        <Giris />
        <Ozellikler />
        <Ürünler />
        <Resume />
        <Referans />
        <Baglanti />
        <AltYazi />
      </div>
    </div>
  );
}

export default App;
