import React from 'react';
import { FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const Media = () => {
  const handleSocialMediaClick = (url) => {
    // Butona tıklandığında yeni bir sekme açılır ve belirtilen URL'ye yönlendirilir
    window.open(url, '_blank');
  };

  return (
    <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4">
          Bizi takip edin
        </h2>
        <div className="flex gap-4">
          
          <span className="bannerIcon" onClick={() => handleSocialMediaClick('https://www.instagram.com/aricandemircelik/?igsh=OGQ5ZDc2ODk2ZA%3D%3D')}>
            <FaInstagram />
          </span>
          
        </div>
      </div>
    </div>
  );
};

export default Media;
