import React from 'react';
import Title from '../layouts/Title';
import BaglantiSol from './BaglantiSol';

const Baglanti = () => {
  const addresses = [
    {
      title: 'Arıcan Group Demir-Çelik-İnşaat',
      content: 'Alacaatlı Mah. 3341.Sk No:1 Çankaya, Ankara',
      location: '📍 Ankara, Türkiye',
      phoneNumber: '+90 535 729 6911',
      email: 'info@aricandemircelik.com.tr',
    },
  ];

  return (
    <section id="Bağlantı" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title des="Bize Ulaşın" />
      </div>
      <div className="w-full">
        <div className="w-full h-auto flex flex-col lg:flex-row justify-between gap-8">
          <BaglantiSol />
          <div className="w-full lg:w-[60%] h-full py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] flex flex-col p-4 lg:p-8 rounded-lg shadow-shadowOne">
            <div className="w-full flex flex-col gap-6">
              {addresses.map((address, index) => (
                <div key={index} className="address mb-10">
                  <h2 className="text-4xl font-bold mb-5">{address.title}</h2>
                  <h1 className="text-2xl font mb-5">{address.content}</h1>
                  <h1 className="text-xl text-gray-500 mb-5">{address.location}</h1>
                  <h1 className="text-xl text-gray-500 mb-5">📞 {address.phoneNumber}</h1>
                  <h1 className="text-xl text-gray-500 mb-5">✉️ {address.email}</h1>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Baglanti;
