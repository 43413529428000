// Testimonial.js

import React from 'react';
import Slider from 'react-slick';
import Title from '../layouts/Title';
import testimonialOne from '../../assets/images/testmonial/testimonialOne.png';
import testimonialTwo from '../../assets/images/testmonial/testimonialTwo.png';
import testimonialThree from '../../assets/images/testmonial/testimonialThree.png';
import testimonialFour from '../../assets/images/testmonial/testimonialFour.png';
import testimonialFive from '../../assets/images/testmonial/testimonialFive.png';
import './referans.css'; // CSS dosyanızı ekleyin

const Referans = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: null, // "previous" okunu kaldırır
    nextArrow: null, // "next" okunu kaldırır
  };

  return (
    <section id="Referans" className="w-full py-10 border-b-[1px] border-b-black bg-gradient-to-r from-[#1e2024] to-[#23272b]">
      <div className="flex justify-center items-center text-center mb-5">
        <Title des="Referanslar" />
      </div>
      <div className="max-w-6xl mx-auto">
        <Slider {...settings} className="testimonial-slider">
          {[testimonialOne, testimonialTwo, testimonialThree, testimonialFour, testimonialFive].map((testimonial, index) => (
            <div key={index} className="w-full h-[220px] md:w-[220px] lg:w-[220px] mx-1">
              <div className="w-full h-full p-2 rounded-lg shadow-shadowOne flex flex-col justify-center items-center overflow-hidden">
                <img
                  className="w-full h-full rounded-lg object-cover testimonial-img"
                  src={testimonial}
                  alt={`testimonial${index + 1}`}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Referans;
