import React, {  useState } from 'react'
import Title from '../layouts/Title';
import İsler from './İsler';
import Basarı from './Basarı';
import VizyonMisyon from "./VizyonMisyon"

const Resume = () => {
   const [islerData, setİslerData] = useState(true);
   const [vmData, setVMData] = useState(false);
   const [basariData, setBasariData] = useState(false);
  return (
    <section id="Deneyim" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title des="Deneyim" />
      </div>
      <div>
        <ul className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
          <li
            onClick={() =>
              setİslerData(true) &
              setVMData(false) &
              setBasariData(false)
            }
            className={`${
              islerData
                ? "border-designColor rounded-lg"
                : "border-transparent"
            } resumeLi`}
          >
            İş
          </li>
          
          <li
            onClick={() =>
              setİslerData(false) &
              setVMData(true) &
              setBasariData(false)
            }
            className={`${
              vmData
                ? "border-designColor rounded-lg"
                : "border-transparent"
            } resumeLi`}
          >
            Vizyon-Misyon
          </li>
          <li
            onClick={() =>
              setİslerData(false) &
              setVMData(false) &
              setBasariData(true)
            }
            className={`${
              basariData
                ? "border-designColor rounded-lg"
                : "border-transparent"
            } resumeLi`}
          >
            Başarılar
          </li>
        </ul>
      </div>
      {islerData && <İsler />}
      {basariData && <Basarı />}
      {vmData && <VizyonMisyon />}
 
    </section>
  );
}

export default Resume