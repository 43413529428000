import { AiFillAppstore } from "react-icons/ai";
import { SiProgress } from "react-icons/si";

// Features Data
export const featuresData = [
  {
    id: 1,
    title: "Sac-Metal-Demir",
    des: "Arıcan Group, metal sektöründe öncü bir konumda bulunmaktadır. Bu alanında uzmanlaşan şirketimiz, kaliteli ürünleri ve güvenilir hizmeti ile tanınır.",
  },
  {
    id: 2,
    icon: <AiFillAppstore />,
    title: "Lojistik",
    des: " Arıcan Group, yüksek kaliteli ve güvenilir lojistik çözümleri sunarak müşteri memnuniyetini ön planda tutar. Uzman kadrosuyla, lojistik ihtiyaçlarına yenilikçi ve etkili çözümler sunmaktadır.",
  },
  {
    id: 3,
    icon: <SiProgress />,
    title: "İnşaat",
    des: "Arıcan Group, inşaat sektöründe liderliğiyle bilinen bir şirkettir. Yenilikçi projeleri ve kaliteli işçiliğiyle müşteri memnuniyetini ön planda tutar. Tecrübesi ve uzman ekibi ile inşaat alanında sürdürülebilir çözümler sunar.",
  },
];
